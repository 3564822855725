import React from "react";

const Terms = () => {
  return (
    <div className="w-full bg-white">
      <div className="max-w-7xl mx-auto px-10 md:px-20 py-20">
        <div>
          <div className="text-black text-3xl title title pb-4 ">
            Acceptance of Terms
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            This Privacy Policy outlines how Astra Intel LLC ("Astra Intel")
            collects, uses, and discloses information about you when you visit
            our website (astraintel.co) or use any of our associated online
            platforms, including mobile sites, applications, and other services
            that link to this Privacy Policy (collectively, the "Site").
          </p>
        </div>
        <div>
          <div className="text-black text-2xl title title pb-4 pt-4">
            Use of the Site
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            You agree to use the Site for lawful purposes only. You are
            prohibited from using the Site to engage in any illegal activities,
            infringe on the rights of others, or disrupt the operation of the
            Site.
          </p>
        </div>
        <div>
          <div className="text-black text-2xl title title pb-4 pt-4">
            Intellectual Property
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            All content on the Site, including text, graphics, logos, and
            images, is the property of Astra Intel LLC or its content suppliers
            and is protected by intellectual property laws. You may not
            reproduce, distribute, or create derivative works from any content
            on the Site without our express written permission.
          </p>
        </div>
        <div>
          <div className="text-black text-2xl title title pb-4 pt-4">
            User Accounts
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            If you create an account on the Site, you are responsible for
            maintaining the confidentiality of your account and password. You
            agree to accept responsibility for all activities that occur under
            your account. Astra Intel LLC reserves the right to terminate
            accounts at our discretion.
          </p>
        </div>
        <div>
          <div className="text-black text-2xl title title pb-4 pt-4">
            Privacy
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            Your use of the Site is also governed by our Privacy Policy, which
            outlines how we collect, use, and protect your information. By using
            the Site, you agree to our Privacy Policy.
          </p>
        </div>
        <div>
          <div className="text-black text-2xl title title pb-4 pt-4">
            Third-Party Links
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            The Site may contain links to third-party websites or services.
            These links are provided for your convenience only. We do not
            endorse or assume any responsibility for the content or practices of
            any third-party sites.
          </p>
        </div>
        <div>
          <div className="text-black text-2xl title title pb-4 pt-4">
            Disclaimers
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            The Site is provided on an “as-is” and “as-available” basis. Astra
            Intel LLC makes no warranties, express or implied, regarding the
            Site, including but not limited to, the accuracy, reliability, or
            availability of the Site.
          </p>
        </div>
        <div>
          <div className="text-black text-2xl title title pb-4 pt-4">
            Limitation of Liability
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            To the maximum extent permitted by law, Astra Intel LLC shall not be
            liable for any indirect, incidental, special, consequential, or
            punitive damages arising out of or related to your use of the Site.
          </p>
        </div>
        <div>
          <div className="text-black text-2xl title title pb-4 pt-4">
            Indemnification
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            You agree to indemnify and hold harmless Astra Intel LLC and its
            affiliates, officers, agents, and employees from any claims,
            liabilities, damages, and expenses, including attorney’s fees,
            arising out of your use of the Site or your violation of these Terms
            of Service.
          </p>
        </div>
        <div>
          <div className="text-black text-2xl title title pb-4 pt-4">
            Changes to the Terms
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            We may update these Terms of Service from time to time. If we make
            changes, we will notify you by revising the date at the top of the
            terms. Your continued use of the Site after such changes constitutes
            your acceptance of the new Terms of Service.
          </p>
        </div>
        <div>
          <div className="text-black text-2xl title title pb-4 pt-4">
            Governing Law
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            These Terms of Service are governed by and construed in accordance
            with the laws of the State of Virginia without regard to its
            conflict of law principles. Any legal action or proceeding related
            to the Site shall be brought exclusively in a federal or state court
            of competent jurisdiction located in Arlington, Virginia
          </p>
        </div>
        <div>
          <div className="text-black text-2xl title title pb-4 pt-4">
            Contact Us
          </div>
          <p className=" text-primary text-lg leading-6 pb-4 title font-normal">
            If you have any questions about these Terms of Service, please
            contact us at info@astrainintel.co.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
